//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      

      form : {
        id : '',
        jns_golongan_id : '',
        pangkat : '',
        gol : '',
        ruang : '',
        keterangan : '',
      },
      id : '',
      jns_golongan_id : '',
      list_data : [],


      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_chat : false,
      mdl_maps : false,
      btn_add: false,
      mdl_maps_tambah : false,
      mdl_img : false,
      mdl_lihat_data : false,
    }
  },
  methods: {


    getData : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_simpeg_golongan + "readData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.cek_load_data = false;
              // console.log(res_data);
      });
    },


    addData : function() {

      var formData = new FormData();

      formData.append('jns_golongan_id', this.form.jns_golongan_id);
      formData.append('pangkat', this.form.pangkat);
      formData.append('gol', this.form.gol);
      formData.append('ruang', this.form.ruang);
      formData.append('keterangan', this.form.keterangan);

      fetch(this.$store.state.url.URL_simpeg_golongan + "createData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body : formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getData();
          // console.log(res_data);
      });
      // console.log(this.form.jeniskategorilokasi)
    },


    editData : function(){

      var formData = new FormData();

      formData.append('jns_golongan_id', this.form.jns_golongan_id);
      formData.append('pangkat', this.form.pangkat);
      formData.append('gol', this.form.gol);
      formData.append('ruang', this.form.ruang);
      formData.append('keterangan', this.form.keterangan);

      fetch(this.$store.state.url.URL_simpeg_golongan + "editData", {
          method: "POST",
          headers: {
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getData();
      });
    },

    removeData : function(idnya){
      fetch(this.$store.state.url.URL_simpeg_golongan + "deleteData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            jns_golongan_id : idnya,
          })
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getData();
      });

    },

    selectData : function(data){
      this.form = {
        id : data.id,
        jns_golongan_id : data.jns_golongan_id,
        pangkat : data.pangkat,
        gol : data.gol,
        ruang : data.ruang,
        keterangan : data.keterangan,
      };
    },



    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getData();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getData();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getData();
        },

        indicatorColor : function(data){
          if (data == 0) {
            return {
              bg : `bg-orange-2`,
              status : false
            }  
          }
          else if(data == 1){
            return {
              bg : `bg-blue-1`,
              status : false
            }
          }
          else if (data == 2){
            return {
              bg : `bg-red-2`,
              status : true
            }
          }

        }


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    // this.$store.commit("listJeniskategorilokasi");
    // this.$store.commit("getStorage");
    var get_profile = JSON.parse(localStorage.profile);
    this.unit_kerja = get_profile.profile.unit_kerja;
    this.getData();

  },
}
